@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
:root {
    color-scheme: light; /* both supported */
    --primary-base: blue;
    --primary-contrast: white;
}

html,
body,
#root {
    width: auto !important;
    height: auto !important;
    min-width: 100vw;
    min-height: 100vh;
    font-family: "Roboto", sans-serif;
    overflow-x: hidden;
}

#root {
    display: flex;
    flex-direction: column;
}

.fade-enter .box-animate {
    opacity: 0;
    transform: translateX(50%);
}
.fade-enter-active .box-animate {
    opacity: 1;
    transform: translateX(0%);
}
.fade-exit .box-animate {
    opacity: 1;
    transform: translateX(0%);
}
.fade-exit-active .box-animate {
    opacity: 0;
    /* transform: translateX(-100%); */
}
.fade-enter-active .box-animate,
.fade-exit-active .box-animate {
    transition: opacity 300ms, transform 300ms;
}

@media (min-width: 640px) {
    .sm\:overflow-initial {
        overflow: initial !important;
    }
}

@keyframes animateCardElection {
    0% {
    }
    50% {
        transform: scale(1.08);
    }
    100% {
        background-color: var(--primary-base);
        transform: scale(1);
    }
}

@keyframes animateCardElectionColor {
    0% {
    }
    100% {
        color: white;
    }
}

.election-card-selected {
    animation-name: animateCardElection;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    /* animation-timing-function: ease-in; */
}
.election-card-selected p {
    /* background: red; */
    animation-name: animateCardElectionColor;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    /* animation-timing-function: ease-in; */
}

@keyframes animationVotingQty {
    0% {
        /* transform: translateY(-100%); */
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.animation-voting-qty {
    opacity: 0;
    transform: translateY(-100%);
    animation-name: animationVotingQty;
    animation-delay: 500ms;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
}

@media (min-width: 640px) {
    .animation-voting-qty {
        transform: translateY(100%);
    }
}

.ant-modal .ant-modal-footer {
    display: flex;
    justify-content: space-between;
}

.ant-modal .ant-modal-footer .ant-btn {
    padding-left: 20px;
    padding-right: 20px;
}